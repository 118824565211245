import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const NavBarStyling = styled.section`
  position: sticky;
  top: 0;
  z-index: 1020;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

  a {
    font-size: 16px;
    font-weight: 500;
  }
  #main {
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0.9;
  }
  .site-logo {
    display: flex;
    padding: 8px 0;
  }
  .index-link {
    padding: 10px 0;
  }
  .dropdown-item {
    border-radius: 0;
    color: #0e2b89;
    padding: 0;
    &:hover {
      color: #2b53d6;
    }
    a {
      font-weight: 400;
      border-bottom: 1px solid rgba(0, 0, 0, 0.04);
      color: #263045;
      display: block;
      padding: 15px 0;
      padding: 15px 10px;
      position: relative;
      font-size: 15px;
      line-height: 16px;
    }
  }
  .navbar-nav a {
    padding: 0.5rem 0rem;
  }
  .navbar-nav .dropdown-menu a {
    padding: 5px 0.5rem 5px 0.5rem;
  }
  .dropdown-item {
  }
  .navbar-light .navbar-nav .nav-link {
    color: #263045;
  }
  @media only screen and (min-width: 992px) {
    .main-nav-menu {
      .main-link {
        margin: 0 15px;
        border: none;
        position: relative;
        border-bottom: 3px solid white;
        padding: ${props => (props.shrink ? "14px 0" : "1.5rem 0")};
        transition: all 0.4s ease-in-out;
        color: #263045;
        &:hover {
          border-bottom: 3px solid #0e2b89;
          transition: all ease-in-out 0.2s;
          color: #2b53d6;
        }
        &:active {
          border-bottom: 3px solid #0e2b89;
          transition: all ease-in-out 0.2s;
          color: #2b53d6;
        }
      }
      .nav-link {
        padding: 0;
        &:hover {
          color: #2b53d6;
        }
        &:active {
          color: #0e2b89;
        }
      }
      .dropdown-toggle::after {
        content: none;
      }
      .dropdown a {
        color: #263045;
      }
      .dropdown-menu {
        border-style: none;
        border-radius: 0;
        padding: 0;
      }
      .dropdown-item {
        border-radius: 0;
        color: #0e2b89;
        padding: 0;
        &:hover {
          color: #2b53d6;
        }
        a {
          font-weight: 400;
          border-bottom: 1px solid rgba(0, 0, 0, 0.04);
          color: #263045;
          display: block;
          padding: 15px 0;
          padding: 15px 10px;
          position: relative;
          font-size: 15px;
          line-height: 16px;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
        }
      }
    }
    .site-logo {
      padding: 0;
    }
  }
`

const Topbar = styled.section`
  .navbar {
    padding: 0;
    background: red;
    background-color: #0e2b89;
  }
  .nav-link {
    margin: 0;
  }
  a.site-switch {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }
  .topbar-cta {
    background-color: #2b53d6;
    border-color: #0e2b89;
    &:hover {
      background-color: #263045;
      cursor: pointer;
    }
    color: #fff;
  }
  a.topbar-cta {
    padding: 8px 30px;
  }
  svg {
    width: 14px;
    height: 14px;
  }
  .topbar-contact {
    padding: 8px 30px;
    a {
      font-size: 15px;
      font-weight: 400;
      color: #fff;
    }
  }
`

export default function HeaderBootstrap(props) {
  const [shrink, setShrink] = useState(false)

  const data = useStaticQuery(graphql`
    query headerbootstraplogo {
      file(relativePath: { eq: "compulease_logo.png" }) {
        childImageSharp {
          fixed(height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const NavMenuLinks =
    props.headerType === "business" ? (
      <BusinessNavItems />
    ) : props.headerType === "education" ? (
      <EducationNavItems />
    ) : props.headerType === "index" ? (
      <IndexNavItems />
    ) : (
      console.log("headerType & NavMenuLinks not set")
    )

  const SiteSwitch =
    props.headerType === "business" ? (
      <Link
        to={"/education/Home"}
        className="site-switch"
        id="link-to-education"
      >
        Switch to Education
      </Link>
    ) : props.headerType === "education" ? (
      <Link to={"/business/Home"} className="site-switch" id="link-to-business">
        Switch to Business
      </Link>
    ) : null

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 100) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  })

  return (
    <>
      <NavBarStyling shrink={shrink}>
        <Topbar>
          <Navbar collapseOnSelect expand="xl">
            <Container>
              <Navbar.Collapse>
                <Nav className="mr-auto">{SiteSwitch}</Nav>
                <Nav>
                  <div className="topbar-contact">
                    <a href="tel:+441625531777">
                      <FontAwesomeIcon icon={faPhoneAlt} color="white" />
                      &nbsp;&nbsp;01625 531 777
                    </a>
                  </div>
                  <Link
                    className="topbar-cta"
                    to={
                      "/" +
                      (props.headerType === "index"
                        ? "ContactUs/"
                        : props.headerType + "/ContactUs/")
                    }
                  >
                    Get a quote
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Topbar>
        <Navbar collapseOnSelect expand="lg" id="main">
          <Container>
            <Navbar.Brand>
              <Link className="site-logo" to={"/"}>
                <Img
                  fixed={data.file.childImageSharp.fixed}
                  alt="Compulease logo"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto main-nav-menu">{NavMenuLinks}</Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </NavBarStyling>
    </>
  )
}

function IndexNavItems() {
  return (
    <>
      <Link className="main-link index-link" to={"/education/Home"}>
        Education Sector
      </Link>
      <Link className="main-link index-link" to={"/business/Home"}>
        Business Sector
      </Link>
    </>
  )
}

function EducationNavItems() {
  const [show, setShow] = useState(false)
  const showDropdown = e => {
    setShow(!show)
  }
  const hideDropdown = e => {
    setShow(false)
  }
  return (
    <>
      <Link className="main-link" to={"/education/Home"}>
        Home
      </Link>

      <NavDropdown
        title="Solutions"
        id="collasible-nav-dropdown"
        className="main-link"
        show={show}
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}
      >
        <NavDropdown.Item>
          <Link to={"/education/IT"}>IT</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/education/Laptops/"}>Laptop and iPads</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/education/Networks/"}>Networks</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/education/Bespoke/"}>Bespoke</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/education/InteractiveAudiovisual/"}>Audiovisual</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/education/ClimateControl/"}>Climate Control</Link>
        </NavDropdown.Item>
      </NavDropdown>

      <Link className="main-link" to={"/education/BenefitsOfLeasing/"}>
        Benefits Of Leasing
      </Link>

      <Link className="main-link" to={"/education/FinanceOnly/"}>
        Finance Only
      </Link>
    </>
  )
}

function BusinessNavItems() {
  const [show, setShow] = useState(false)
  const showDropdown = e => {
    setShow(!show)
  }
  const hideDropdown = e => {
    setShow(false)
  }
  const [showSupport, setShowSupport] = useState(false)
  const showDropdownSupport = e => {
    setShowSupport(!show)
  }
  const hideDropdownSupport = e => {
    setShowSupport(false)
  }
  return (
    <>
      <Link to={"/business/Home"} className="main-link">
        Home
      </Link>
      <NavDropdown
        title="Solutions"
        id="collasible-nav-dropdown"
        className="main-link"
        show={show}
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}
        to={"/business/Support"}
      >
        <NavDropdown.Item>
          <Link to={"/business/solutions/PCsAndServers/"}>PCs and Servers</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/solutions/Laptops/"}>Laptop Rentals</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/solutions/WirelessNetworks/"}>
            Wireless Networks
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/solutions/BespokeOfficeFurniture/"}>
            Bespoke Office Furniture
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/solutions/Audiovisual/"}>Audiovisual</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/solutions/PresentationEquipment"}>
            Presentation Equipment
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
      <Link className="main-link" to={"/business/Leasing"}>
        Benefits of Leasing
      </Link>
      <NavDropdown
        title="Support"
        id="collasible-nav-dropdown"
        className="main-link"
        show={showSupport}
        onMouseEnter={showDropdownSupport}
        onMouseLeave={hideDropdownSupport}
        to={"/business/Support"}
      >
        <NavDropdown.Item>
          <Link to={"/business/Support/"}>Support for you</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/Support/#SupportSolutions"}>
            Flexible Options
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/Support/#MaintenanceAgreement"}>
            Maintenance Agreement
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/Support/#Warranty"}>Warranty</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/Support/#Warranty"}>
            Equipment Protection Cover
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/Support/#Warranty"}>Asset Disposal</Link>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <Link to={"/business/Support/#PeaceOfMindGuarantee"}>
            Peace of Mind Guarantee
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
      <Link className="main-link" to={"/business/FinanceOnly"}>
        Finance Only
      </Link>
    </>
  )
}
