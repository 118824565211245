import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

const FooterA = styled.div`
  padding: 2rem 0;
  background-color: #263045;
`

const Text = styled.p`
  color: white !important;
`

const FooterLogo = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  .img-logo {
    height: 45px;
  }
`

const QuickLink = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  background-color: #222c40;
  color: rgba(255, 255, 255, 0.8);
  .icon {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    background-color: #0e2b89;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    font-size: 28px;
    width: 28px;
    height: 28px;
  }
  a {
    font-size: 17px;
    align-items: center;
    display: flex;
    margin-left: 1rem;
    color: white;
  }
`

const FooterB = styled.div`
  padding: 0.5rem 0;
  li {
    color: white;
  }
  background-color: #222c40;
  span {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
  }
  @media (max-width: 576px) {
    text-align: center;
  }
`

const FooterNavMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
  @media (max-width: 576px) {
    float: none;
  }
  li {
    display: inline-block;
    padding-left: 2px;
    padding-right: 16px;
    font-size: 14px;
    padding-bottom: 0;
    a {
      color: white;
    }
    &:last-child {
      padding-right: 0;
    }
    &:first-child {
      padding-left: 0;
    }
    &:not(:last-child):after {
      position: absolute;
      content: "|";
      padding-left: 7px;
    }
  }
`

export default function Footer(props) {
  const logo = useStaticQuery(graphql`
    query getLogo {
      file(relativePath: { eq: "compulease_logo_rounded.png" }) {
        childImageSharp {
          fixed(height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const ContactUs = (
    <Link
      to={
        "/" +
        (props.footerType === "index"
          ? "ContactUs/"
          : props.footerType + "/ContactUs/")
      }
    >
      Contact us
    </Link>
  )

  const TermsAndConditions = (
    <Link
      to={
        "/" +
        (props.footerType === "index"
          ? "TermsAndConditions/"
          : props.footerType + "/TermsAndConditions/")
      }
    >
      Terms and Conditions
    </Link>
  )

  const GDPR = (
    <Link
      to={
        "/" +
        (props.footerType === "index" ? "GDPR/" : props.footerType + "/GDPR/")
      }
    >
      GDPR
    </Link>
  )

  return (
    <footer>
      <FooterA>
        <Container>
          <Row>
            <Col
              xs={{ span: "12", order: "1" }}
              md={{ span: "6", order: "1" }}
              lg={{ span: "4", order: "0" }}
            >
              <Text>
                Compulease™ is the UK’s largest IT leasing provider and we are
                committed to providing a service which enables businesses,
                schools, colleges and universities to obtain IT equipment and
                keep in touch with technology as it develops, in the most cost
                efficient way.
              </Text>
            </Col>
            <Col
              xs={{ span: "12", order: "0" }}
              md={{ span: "12", order: "0" }}
              lg={{ span: "4", order: "1" }}
            >
              <FooterLogo>
                <Img
                  fixed={logo.file.childImageSharp.fixed}
                  className="img-logo"
                  alt="Compulease™ logo"
                />
              </FooterLogo>
            </Col>
            <Col
              xs={{ span: "12", order: "2" }}
              md={{ span: "6", order: "2" }}
              lg={{ span: "4", order: "2" }}
            >
              <Row>
                <Col xs="12">
                  <QuickLink>
                    <div className="icon">
                      <FontAwesomeIcon icon={faPhone} color="white" size="lg" />
                    </div>
                    <a href="tel:+441625531777">01625 531 777</a>
                  </QuickLink>
                </Col>
                <Col xs="12">
                  <QuickLink>
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        color="white"
                        size="lg"
                      />
                    </div>
                    <a href="mailto:info@compulease.co.uk">
                      info@compulease.co.uk
                    </a>
                  </QuickLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </FooterA>
      <FooterB>
        <Container>
          <Row>
            <Col md="6">
              <span>
                Copyright © {new Date().getFullYear()}&nbsp;Compulease
              </span>
            </Col>
            <Col md="6">
              <FooterNavMenu>
                <li>{ContactUs}</li>
                <li>{TermsAndConditions}</li>
                <li>{GDPR}</li>
              </FooterNavMenu>
            </Col>
          </Row>
        </Container>
      </FooterB>
    </footer>
  )
}
