import React, { useContext } from "react"
import { myContext } from "../ThemeContext"
import Header from "./Header"
import Footer from "../components/Footer"
import styled from "styled-components"

const LayoutSection = styled.section`
  body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #6e6e6e;
    background-color: white;
    letter-spacing: 0px;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #6e6e6e;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #0e2b89;
    font-family: "Roboto", sans-serif;
    margin-bottom: 16px;
    font-weight: 400;
    transition: all 0.4s ease-in-out;
  }
  a {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
  }
  span,
  li {
    font-family: "Roboto", sans-serif;
  }
`

export default function Layout(props) {
  const theme = useContext(myContext)
  return (
    <LayoutSection theme={theme}>
      <Header headerType={props.headerType} />
      {props.children}
      <Footer footerType={props.headerType} />
    </LayoutSection>
  )
}
